:host {
  --sds-g-line-height: 1.5;
  --c360-g-line-height: var(--sds-g-line-height);
  --sds-g-font-scale-10: 51.952px;
  --sds-g-color-palette-neutral-20: #2e2e2e;
  --c360-g-color-palette-neutral-20: var(--sds-g-color-palette-neutral-20);
  --sds-g-color-palette-orange-30: #5f3e02;
  --c360-g-color-palette-orange-30: var(--sds-g-color-palette-orange-30);
  --sds-g-color-palette-green-60: #3ba755;
  --c360-g-color-palette-green-60: var(--sds-g-color-palette-green-60);
  --sds-g-color-palette-hot-orange-30: #7e2600;
  --c360-g-color-palette-hot-orange-30: var(--sds-g-color-palette-hot-orange-30);
  --sds-g-sizing-border-2: 2px;
  --c360-g-sizing-border-2: var(--sds-g-sizing-border-2);
  --sds-g-color-palette-neutral-10: #181818;
  --c360-g-color-palette-neutral-10: var(--sds-g-color-palette-neutral-10);
  --sds-g-color-palette-yellow-15: #2e2204;
  --c360-g-color-palette-yellow-15: var(--sds-g-color-palette-yellow-15);
  --sds-g-color-palette-orange-20: #3e2b02;
  --c360-g-color-palette-orange-20: var(--sds-g-color-palette-orange-20);
  --sds-g-color-palette-neutral-65: #a0a0a0;
  --c360-g-color-palette-neutral-65: var(--sds-g-color-palette-neutral-65);
  --sds-g-color-palette-green-50: #2e844a;
  --c360-g-color-palette-green-50: var(--sds-g-color-palette-green-50);
  --sds-g-color-palette-hot-orange-20: #541d01;
  --c360-g-color-palette-hot-orange-20: var(--sds-g-color-palette-hot-orange-20);
  --sds-g-color-palette-orange-10: #201600;
  --c360-g-color-palette-orange-10: var(--sds-g-color-palette-orange-10);
  --sds-g-color-palette-green-40: #22683e;
  --c360-g-color-palette-green-40: var(--sds-g-color-palette-green-40);
  --sds-g-color-palette-hot-orange-10: #281202;
  --c360-g-color-palette-hot-orange-10: var(--sds-g-color-palette-hot-orange-10);
  --sds-g-color-palette-orange-65: #f38303;
  --c360-g-color-palette-orange-65: var(--sds-g-color-palette-orange-65);
  --sds-g-color-palette-green-95: #ebf7e6;
  --c360-g-color-palette-green-95: var(--sds-g-color-palette-green-95);
  --sds-g-color-palette-hot-orange-65: #ff784f;
  --c360-g-color-palette-hot-orange-65: var(--sds-g-color-palette-hot-orange-65);
  --sds-g-font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --c360-g-font-family: var(--sds-g-font-family);
  --sds-g-color-palette-green-30: #194e31;
  --c360-g-color-palette-green-30: var(--sds-g-color-palette-green-30);
  --sds-g-color-palette-cloud-blue-15: #0a2636;
  --c360-g-color-palette-cloud-blue-15: var(--sds-g-color-palette-cloud-blue-15);
  --sds-g-color-palette-indigo-15: #1f0974;
  --c360-g-color-palette-indigo-15: var(--sds-g-color-palette-indigo-15);
  --sds-g-link-color-active: #032d60;
  --c360-g-link-color-active: var(--sds-g-link-color-active);
  --sds-g-color-neutral-inverse-1: #181818;
  --c360-g-color-neutral-inverse-1: var(--sds-g-color-neutral-inverse-1);
  --sds-g-color-palette-green-20: #0e3522;
  --c360-g-color-palette-green-20: var(--sds-g-color-palette-green-20);
  --sds-g-color-error-base-1: #ffffff;
  --c360-g-color-error-base-1: var(--sds-g-color-error-base-1);
  --sds-g-shadow-1: 0 0 2px 0 #18181814, 0 2px 4px 1px #18181828;
  --c360-g-shadow-1: var(--sds-g-shadow-1);
  --sds-g-color-brand-inverse-1: #001639;
  --c360-g-color-brand-inverse-1: var(--sds-g-color-brand-inverse-1);
  --sds-g-color-neutral-inverse-2: #2e2e30;
  --c360-g-color-neutral-inverse-2: var(--sds-g-color-neutral-inverse-2);
  --sds-g-color-palette-green-10: #071b12;
  --c360-g-color-palette-green-10: var(--sds-g-color-palette-green-10);
  --sds-g-color-error-base-2: #fef1ee;
  --c360-g-color-error-base-2: var(--sds-g-color-error-base-2);
  --sds-g-color-palette-green-65: #41b658;
  --c360-g-color-palette-green-65: var(--sds-g-color-palette-green-65);
  --sds-g-color-neutral-base-1: #ffffff;
  --c360-g-color-neutral-base-1: var(--sds-g-color-neutral-base-1);
  --sds-g-shadow-2: 0 2px 8px -2px #18181814, 0 8px 12px -2px #18181828;
  --c360-g-shadow-2: var(--sds-g-shadow-2);
  --sds-g-color-brand-base-1: #ffffff;
  --c360-g-color-brand-base-1: var(--sds-g-color-brand-base-1);
  --sds-g-color-brand-inverse-2: #032d60;
  --c360-g-color-brand-inverse-2: var(--sds-g-color-brand-inverse-2);
  --sds-g-color-neutral-inverse-3: #444447;
  --c360-g-color-neutral-inverse-3: var(--sds-g-color-neutral-inverse-3);
  --sds-g-color-palette-neutral-15: #242424;
  --c360-g-color-palette-neutral-15: var(--sds-g-color-palette-neutral-15);
  --sds-g-color-error-base-3: #feded8;
  --c360-g-color-error-base-3: var(--sds-g-color-error-base-3);
  --sds-g-color-neutral-base-2: #f3f3f3;
  --c360-g-color-neutral-base-2: var(--sds-g-color-neutral-base-2);
  --sds-g-shadow-3: 0 12px 24px -4px #18181814, 0 16px 32px -4px #18181828;
  --c360-g-shadow-3: var(--sds-g-shadow-3);
  --sds-g-color-brand-base-2: #eef4ff;
  --c360-g-color-brand-base-2: var(--sds-g-color-brand-base-2);
  --sds-g-color-brand-inverse-3: #014486;
  --c360-g-color-brand-inverse-3: var(--sds-g-color-brand-inverse-3);
  --sds-g-color-warning-base-contrast-1: #a86403;
  --c360-g-color-warning-base-contrast-1: var(--sds-g-color-warning-base-contrast-1);
  --sds-g-color-neutral-inverse-4: #5a5c61;
  --c360-g-color-neutral-inverse-4: var(--sds-g-color-neutral-inverse-4);
  --sds-g-spacing-1: 4px;
  --c360-g-spacing-1: var(--sds-g-spacing-1);
  --sds-g-color-palette-orange-15: #371e03;
  --c360-g-color-palette-orange-15: var(--sds-g-color-palette-orange-15);
  --sds-g-color-error-base-4: #feb8ab;
  --c360-g-color-error-base-4: var(--sds-g-color-error-base-4);
  --sds-g-color-palette-hot-orange-15: #421604;
  --c360-g-color-palette-hot-orange-15: var(--sds-g-color-palette-hot-orange-15);
  --sds-g-color-neutral-base-3: #e5e5e5;
  --c360-g-color-neutral-base-3: var(--sds-g-color-neutral-base-3);
  --sds-g-shadow-4: 0 24px 48px -4px #18181833;
  --c360-g-shadow-4: var(--sds-g-shadow-4);
  --sds-g-color-brand-base-3: #d8e6fe;
  --c360-g-color-brand-base-3: var(--sds-g-color-brand-base-3);
  --sds-g-color-brand-inverse-4: #0b5cab;
  --c360-g-color-brand-inverse-4: var(--sds-g-color-brand-inverse-4);
  --sds-g-color-warning-base-contrast-2: #8c4b02;
  --c360-g-color-warning-base-contrast-2: var(--sds-g-color-warning-base-contrast-2);
  --sds-g-spacing-2: 8px;
  --c360-g-spacing-2: var(--sds-g-spacing-2);
  --sds-g-color-neutral-base-4: #c9c9cb;
  --c360-g-color-neutral-base-4: var(--sds-g-color-neutral-base-4);
  --sds-g-color-brand-base-4: #aacbff;
  --c360-g-color-brand-base-4: var(--sds-g-color-brand-base-4);
  --sds-g-font-leading: 1.5;
  --c360-g-font-leading: var(--sds-g-font-leading);
  --sds-g-color-warning-base-contrast-3: #6f3400;
  --c360-g-color-warning-base-contrast-3: var(--sds-g-color-warning-base-contrast-3);
  --sds-g-color-success-base-1: #ffffff;
  --c360-g-color-success-base-1: var(--sds-g-color-success-base-1);
  --sds-g-spacing-3: 12px;
  --c360-g-spacing-3: var(--sds-g-spacing-3);
  --sds-g-color-palette-violet-90: #f2defe;
  --c360-g-color-palette-violet-90: var(--sds-g-color-palette-violet-90);
  --sds-g-spacing-10: 64px;
  --c360-g-spacing-10: var(--sds-g-spacing-10);
  --sds-g-color-warning-base-contrast-4: #4f2100;
  --c360-g-color-warning-base-contrast-4: var(--sds-g-color-warning-base-contrast-4);
  --sds-g-color-success-base-2: #ebf7e6;
  --c360-g-color-success-base-2: var(--sds-g-color-success-base-2);
  --sds-g-spacing-4: 16px;
  --c360-g-spacing-4: var(--sds-g-spacing-4);
  --sds-g-color-palette-green-15: #0c2912;
  --c360-g-color-palette-green-15: var(--sds-g-color-palette-green-15);
  --sds-g-color-palette-violet-80: #e5b9fe;
  --c360-g-color-palette-violet-80: var(--sds-g-color-palette-violet-80);
  --sds-g-spacing-11: 72px;
  --c360-g-spacing-11: var(--sds-g-spacing-11);
  --sds-g-color-success-base-3: #cdefc4;
  --c360-g-color-success-base-3: var(--sds-g-color-success-base-3);
  --sds-g-spacing-5: 24px;
  --c360-g-spacing-5: var(--sds-g-spacing-5);
  --sds-g-radius-border-1: 2px;
  --c360-g-radius-border-1: var(--sds-g-radius-border-1);
  --sds-g-color-palette-violet-70: #d892fe;
  --c360-g-color-palette-violet-70: var(--sds-g-color-palette-violet-70);
  --sds-g-spacing-12: 80px;
  --c360-g-spacing-12: var(--sds-g-spacing-12);
  --sds-g-color-border-base-1: #aeaeae;
  --c360-g-color-border-base-1: var(--sds-g-color-border-base-1);
  --sds-g-color-palette-teal-90: #acf3e4;
  --c360-g-color-palette-teal-90: var(--sds-g-color-palette-teal-90);
  --sds-g-color-success-base-4: #91db8b;
  --c360-g-color-success-base-4: var(--sds-g-color-success-base-4);
  --sds-g-spacing-6: 32px;
  --c360-g-spacing-6: var(--sds-g-spacing-6);
  --sds-g-radius-border-2: 4px;
  --c360-g-radius-border-2: var(--sds-g-radius-border-2);
  --sds-g-color-palette-purple-90: #ece1f9;
  --c360-g-color-palette-purple-90: var(--sds-g-color-palette-purple-90);
  --sds-g-color-palette-violet-60: #cb65ff;
  --c360-g-color-palette-violet-60: var(--sds-g-color-palette-violet-60);
  --sds-g-color-border-base-2: #939393;
  --c360-g-color-border-base-2: var(--sds-g-color-border-base-2);
  --sds-g-color-palette-teal-80: #04e1cb;
  --c360-g-color-palette-teal-80: var(--sds-g-color-palette-teal-80);
  --sds-g-spacing-7: 40px;
  --c360-g-spacing-7: var(--sds-g-spacing-7);
  --sds-g-radius-border-3: 8px;
  --c360-g-radius-border-3: var(--sds-g-radius-border-3);
  --sds-g-color-palette-purple-80: #d78ff5;
  --c360-g-color-palette-purple-80: var(--sds-g-color-palette-purple-80);
  --sds-g-color-palette-violet-50: #ba01ff;
  --c360-g-color-palette-violet-50: var(--sds-g-color-palette-violet-50);
  --sds-g-color-palette-teal-70: #01c3b3;
  --c360-g-color-palette-teal-70: var(--sds-g-color-palette-teal-70);
  --sds-g-spacing-8: 48px;
  --c360-g-spacing-8: var(--sds-g-spacing-8);
  --sds-g-radius-border-4: 16px;
  --c360-g-radius-border-4: var(--sds-g-radius-border-4);
  --sds-g-color-palette-blue-90: #d8e6fe;
  --c360-g-color-palette-blue-90: var(--sds-g-color-palette-blue-90);
  --sds-g-color-palette-purple-70: #c29ef1;
  --c360-g-color-palette-purple-70: var(--sds-g-color-palette-purple-70);
  --sds-g-color-palette-violet-40: #9602c7;
  --c360-g-color-palette-violet-40: var(--sds-g-color-palette-violet-40);
  --sds-g-color-palette-violet-95: #f9f0ff;
  --c360-g-color-palette-violet-95: var(--sds-g-color-palette-violet-95);
  --sds-g-color-success-base-contrast-1: #2e844a;
  --c360-g-color-success-base-contrast-1: var(--sds-g-color-success-base-contrast-1);
  --sds-g-color-palette-teal-60: #06a59a;
  --c360-g-color-palette-teal-60: var(--sds-g-color-palette-teal-60);
  --sds-g-color-palette-red-90: #feded8;
  --c360-g-color-palette-red-90: var(--sds-g-color-palette-red-90);
  --sds-g-spacing-9: 56px;
  --c360-g-spacing-9: var(--sds-g-spacing-9);
  --sds-g-color-palette-blue-80: #aacbff;
  --c360-g-color-palette-blue-80: var(--sds-g-color-palette-blue-80);
  --sds-g-color-palette-purple-60: #ad7bee;
  --c360-g-color-palette-purple-60: var(--sds-g-color-palette-purple-60);
  --sds-g-color-palette-violet-30: #730394;
  --c360-g-color-palette-violet-30: var(--sds-g-color-palette-violet-30);
  --sds-g-color-success-base-contrast-2: #22683e;
  --c360-g-color-success-base-contrast-2: var(--sds-g-color-success-base-contrast-2);
  --sds-g-font-scale-1: 18px;
  --sds-g-color-palette-red-80: #feb8ab;
  --c360-g-color-palette-red-80: var(--sds-g-color-palette-red-80);
  --sds-g-color-palette-blue-70: #78b0fd;
  --c360-g-color-palette-blue-70: var(--sds-g-color-palette-blue-70);
  --sds-g-color-palette-purple-50: #9050e9;
  --c360-g-color-palette-purple-50: var(--sds-g-color-palette-purple-50);
  --sds-g-color-palette-violet-20: #520066;
  --c360-g-color-palette-violet-20: var(--sds-g-color-palette-violet-20);
  --sds-g-color-success-base-contrast-3: #194e31;
  --c360-g-color-success-base-contrast-3: var(--sds-g-color-success-base-contrast-3);
  --sds-g-color-warning-base-1: #ffffff;
  --c360-g-color-warning-base-1: var(--sds-g-color-warning-base-1);
  --sds-g-link-color: #0b5cab;
  --c360-g-link-color: var(--sds-g-link-color);
  --sds-g-color-palette-teal-50: #0b827c;
  --c360-g-color-palette-teal-50: var(--sds-g-color-palette-teal-50);
  --sds-g-color-palette-teal-95: #def9f3;
  --c360-g-color-palette-teal-95: var(--sds-g-color-palette-teal-95);
  --sds-g-color-palette-red-70: #fe8f7d;
  --c360-g-color-palette-red-70: var(--sds-g-color-palette-red-70);
  --sds-g-font-scale-2: 20.256px;
  --sds-g-color-palette-blue-60: #1b96ff;
  --c360-g-color-palette-blue-60: var(--sds-g-color-palette-blue-60);
  --sds-g-color-palette-purple-40: #7526e3;
  --c360-g-color-palette-purple-40: var(--sds-g-color-palette-purple-40);
  --sds-g-color-palette-violet-10: #2e0039;
  --c360-g-color-palette-violet-10: var(--sds-g-color-palette-violet-10);
  --sds-g-color-palette-purple-95: #f6f2fb;
  --c360-g-color-palette-purple-95: var(--sds-g-color-palette-purple-95);
  --sds-g-color-palette-violet-65: #d17dfe;
  --c360-g-color-palette-violet-65: var(--sds-g-color-palette-violet-65);
  --sds-g-color-success-base-contrast-4: #0e3522;
  --c360-g-color-success-base-contrast-4: var(--sds-g-color-success-base-contrast-4);
  --sds-g-color-warning-base-2: #fbf3e0;
  --c360-g-color-warning-base-2: var(--sds-g-color-warning-base-2);
  --sds-g-color-palette-teal-40: #056764;
  --c360-g-color-palette-teal-40: var(--sds-g-color-palette-teal-40);
  --sds-g-color-neutral-base-contrast-1: #929398;
  --c360-g-color-neutral-base-contrast-1: var(--sds-g-color-neutral-base-contrast-1);
  --sds-g-color-palette-red-60: #fe5c4c;
  --c360-g-color-palette-red-60: var(--sds-g-color-palette-red-60);
  --sds-g-font-scale-3: 22.784px;
  --sds-g-color-palette-blue-50: #0176d3;
  --c360-g-color-palette-blue-50: var(--sds-g-color-palette-blue-50);
  --sds-g-color-palette-purple-30: #5a1ba9;
  --c360-g-color-palette-purple-30: var(--sds-g-color-palette-purple-30);
  --sds-g-color-palette-pink-90: #fddde3;
  --c360-g-color-palette-pink-90: var(--sds-g-color-palette-pink-90);
  --sds-g-color-warning-base-3: #f9e3b6;
  --c360-g-color-warning-base-3: var(--sds-g-color-warning-base-3);
  --sds-g-color-brand-base-contrast-1: #1b96ff;
  --c360-g-color-brand-base-contrast-1: var(--sds-g-color-brand-base-contrast-1);
  --sds-g-color-palette-teal-30: #024d4c;
  --c360-g-color-palette-teal-30: var(--sds-g-color-palette-teal-30);
  --sds-g-color-neutral-base-contrast-2: #737479;
  --c360-g-color-neutral-base-contrast-2: var(--sds-g-color-neutral-base-contrast-2);
  --sds-g-font-scale-neg-1: 14px;
  --sds-g-color-palette-red-50: #ea001e;
  --c360-g-color-palette-red-50: var(--sds-g-color-palette-red-50);
  --sds-g-font-scale-4: 25.632px;
  --sds-g-color-palette-blue-40: #0b5cab;
  --c360-g-color-palette-blue-40: var(--sds-g-color-palette-blue-40);
  --sds-g-color-palette-purple-20: #401075;
  --c360-g-color-palette-purple-20: var(--sds-g-color-palette-purple-20);
  --sds-g-color-neutral-inverse-contrast-1: #737479;
  --c360-g-color-neutral-inverse-contrast-1: var(--sds-g-color-neutral-inverse-contrast-1);
  --sds-g-color-palette-blue-95: #eef4ff;
  --c360-g-color-palette-blue-95: var(--sds-g-color-palette-blue-95);
  --sds-g-color-brand-inverse-contrast-1: #0176d3;
  --c360-g-color-brand-inverse-contrast-1: var(--sds-g-color-brand-inverse-contrast-1);
  --sds-g-color-palette-pink-80: #fdb6c5;
  --c360-g-color-palette-pink-80: var(--sds-g-color-palette-pink-80);
  --sds-g-color-warning-base-4: #fcc003;
  --c360-g-color-warning-base-4: var(--sds-g-color-warning-base-4);
  --sds-g-color-brand-base-contrast-2: #0176d3;
  --c360-g-color-brand-base-contrast-2: var(--sds-g-color-brand-base-contrast-2);
  --sds-g-color-palette-teal-20: #023434;
  --c360-g-color-palette-teal-20: var(--sds-g-color-palette-teal-20);
  --sds-g-color-error-base-contrast-1: #ea001e;
  --c360-g-color-error-base-contrast-1: var(--sds-g-color-error-base-contrast-1);
  --sds-g-color-palette-teal-65: #03b4a7;
  --c360-g-color-palette-teal-65: var(--sds-g-color-palette-teal-65);
  --sds-g-color-neutral-base-contrast-3: #5a5c61;
  --c360-g-color-neutral-base-contrast-3: var(--sds-g-color-neutral-base-contrast-3);
  --sds-g-font-scale-neg-2: 13px;
  --sds-g-color-palette-red-40: #ba0517;
  --c360-g-color-palette-red-40: var(--sds-g-color-palette-red-40);
  --sds-g-font-scale-5: 28.832px;
  --sds-g-color-palette-blue-30: #014486;
  --c360-g-color-palette-blue-30: var(--sds-g-color-palette-blue-30);
  --sds-g-color-palette-purple-10: #240643;
  --c360-g-color-palette-purple-10: var(--sds-g-color-palette-purple-10);
  --sds-g-color-palette-red-95: #fef1ee;
  --c360-g-color-palette-red-95: var(--sds-g-color-palette-red-95);
  --sds-g-color-neutral-inverse-contrast-2: #929398;
  --c360-g-color-neutral-inverse-contrast-2: var(--sds-g-color-neutral-inverse-contrast-2);
  --sds-g-color-palette-purple-65: #b78def;
  --c360-g-color-palette-purple-65: var(--sds-g-color-palette-purple-65);
  --sds-g-color-brand-inverse-contrast-2: #1b96ff;
  --c360-g-color-brand-inverse-contrast-2: var(--sds-g-color-brand-inverse-contrast-2);
  --sds-g-color-palette-yellow-90: #f9e3b6;
  --c360-g-color-palette-yellow-90: var(--sds-g-color-palette-yellow-90);
  --sds-g-color-palette-pink-70: #fe8aa7;
  --c360-g-color-palette-pink-70: var(--sds-g-color-palette-pink-70);
  --sds-g-color-palette-teal-10: #071b12;
  --c360-g-color-palette-teal-10: var(--sds-g-color-palette-teal-10);
  --sds-g-color-brand-base-contrast-3: #0b5cab;
  --c360-g-color-brand-base-contrast-3: var(--sds-g-color-brand-base-contrast-3);
  --sds-g-color-error-base-contrast-2: #ba0517;
  --c360-g-color-error-base-contrast-2: var(--sds-g-color-error-base-contrast-2);
  --sds-g-color-neutral-base-contrast-4: #181818;
  --c360-g-color-neutral-base-contrast-4: var(--sds-g-color-neutral-base-contrast-4);
  --sds-g-color-palette-red-30: #8e030f;
  --c360-g-color-palette-red-30: var(--sds-g-color-palette-red-30);
  --sds-g-font-scale-neg-3: 12px;
  --sds-g-color-palette-blue-20: #032d60;
  --c360-g-color-palette-blue-20: var(--sds-g-color-palette-blue-20);
  --sds-g-font-scale-6: 32.432px;
  --sds-g-color-neutral-inverse-contrast-3: #adaeb1;
  --c360-g-color-neutral-inverse-contrast-3: var(--sds-g-color-neutral-inverse-contrast-3);
  --sds-g-color-brand-inverse-contrast-3: #78b0fd;
  --c360-g-color-brand-inverse-contrast-3: var(--sds-g-color-brand-inverse-contrast-3);
  --sds-g-color-palette-yellow-80: #fcc003;
  --c360-g-color-palette-yellow-80: var(--sds-g-color-palette-yellow-80);
  --sds-g-color-palette-pink-60: #ff538a;
  --c360-g-color-palette-pink-60: var(--sds-g-color-palette-pink-60);
  --sds-g-color-brand-base-contrast-4: #001639;
  --c360-g-color-brand-base-contrast-4: var(--sds-g-color-brand-base-contrast-4);
  --sds-g-color-error-base-contrast-3: #8e030f;
  --c360-g-color-error-base-contrast-3: var(--sds-g-color-error-base-contrast-3);
  --sds-g-color-palette-red-20: #640103;
  --c360-g-color-palette-red-20: var(--sds-g-color-palette-red-20);
  --sds-g-font-scale-neg-4: 10px;
  --sds-g-color-palette-blue-10: #001639;
  --c360-g-color-palette-blue-10: var(--sds-g-color-palette-blue-10);
  --sds-g-font-scale-7: 36.496px;
  --sds-g-color-palette-blue-65: #57a3fd;
  --c360-g-color-palette-blue-65: var(--sds-g-color-palette-blue-65);
  --sds-g-color-neutral-inverse-contrast-4: #ffffff;
  --c360-g-color-neutral-inverse-contrast-4: var(--sds-g-color-neutral-inverse-contrast-4);
  --sds-g-color-palette-violet-15: #3d0157;
  --c360-g-color-palette-violet-15: var(--sds-g-color-palette-violet-15);
  --sds-g-color-brand-inverse-contrast-4: #ffffff;
  --c360-g-color-brand-inverse-contrast-4: var(--sds-g-color-brand-inverse-contrast-4);
  --sds-g-color-palette-yellow-70: #e4a201;
  --c360-g-color-palette-yellow-70: var(--sds-g-color-palette-yellow-70);
  --sds-g-color-palette-pink-50: #e3066a;
  --c360-g-color-palette-pink-50: var(--sds-g-color-palette-pink-50);
  --sds-g-color-palette-cloud-blue-90: #cfe9fe;
  --c360-g-color-palette-cloud-blue-90: var(--sds-g-color-palette-cloud-blue-90);
  --sds-g-color-palette-indigo-90: #e0e5f8;
  --c360-g-color-palette-indigo-90: var(--sds-g-color-palette-indigo-90);
  --sds-g-radius-border-circle: 100%;
  --c360-g-radius-border-circle: var(--sds-g-radius-border-circle);
  --sds-g-color-error-base-contrast-4: #640103;
  --c360-g-color-error-base-contrast-4: var(--sds-g-color-error-base-contrast-4);
  --sds-g-color-palette-red-10: #300c01;
  --c360-g-color-palette-red-10: var(--sds-g-color-palette-red-10);
  --sds-g-color-palette-red-65: #fe7765;
  --c360-g-color-palette-red-65: var(--sds-g-color-palette-red-65);
  --sds-g-font-scale-8: 41.056px;
  --sds-g-color-palette-yellow-60: #ca8501;
  --c360-g-color-palette-yellow-60: var(--sds-g-color-palette-yellow-60);
  --sds-g-color-palette-pink-40: #b60554;
  --c360-g-color-palette-pink-40: var(--sds-g-color-palette-pink-40);
  --sds-g-color-palette-cloud-blue-80: #90d0fe;
  --c360-g-color-palette-cloud-blue-80: var(--sds-g-color-palette-cloud-blue-80);
  --sds-g-color-palette-pink-95: #fef0f3;
  --c360-g-color-palette-pink-95: var(--sds-g-color-palette-pink-95);
  --sds-g-color-palette-indigo-80: #bec7f6;
  --c360-g-color-palette-indigo-80: var(--sds-g-color-palette-indigo-80);
  --sds-g-font-scale-9: 46.192px;
  --sds-g-color-palette-yellow-50: #a86403;
  --c360-g-color-palette-yellow-50: var(--sds-g-color-palette-yellow-50);
  --sds-g-color-palette-pink-30: #8a033e;
  --c360-g-color-palette-pink-30: var(--sds-g-color-palette-pink-30);
  --c360-g-font-family-monospace: Consolas, Menlo, Monaco, Courier, monospace;
  --sds-g-color-palette-cloud-blue-70: #1ab9ff;
  --c360-g-color-palette-cloud-blue-70: var(--sds-g-color-palette-cloud-blue-70);
  --sds-g-color-palette-indigo-70: #9ea9f1;
  --c360-g-color-palette-indigo-70: var(--sds-g-color-palette-indigo-70);
  --sds-g-color-palette-purple-15: #300b60;
  --c360-g-color-palette-purple-15: var(--sds-g-color-palette-purple-15);
  --sds-g-color-palette-yellow-40: #8c4b02;
  --c360-g-color-palette-yellow-40: var(--sds-g-color-palette-yellow-40);
  --sds-g-color-palette-neutral-90: #e5e5e5;
  --c360-g-color-palette-neutral-90: var(--sds-g-color-palette-neutral-90);
  --sds-g-color-palette-pink-20: #61022a;
  --c360-g-color-palette-pink-20: var(--sds-g-color-palette-pink-20);
  --sds-g-color-palette-yellow-95: #fbf3e0;
  --c360-g-color-palette-yellow-95: var(--sds-g-color-palette-yellow-95);
  --sds-g-color-palette-cloud-blue-60: #0d9dda;
  --c360-g-color-palette-cloud-blue-60: var(--sds-g-color-palette-cloud-blue-60);
  --sds-g-color-palette-indigo-60: #7f8ced;
  --c360-g-color-palette-indigo-60: var(--sds-g-color-palette-indigo-60);
  --sds-g-color-palette-teal-15: #072825;
  --c360-g-color-palette-teal-15: var(--sds-g-color-palette-teal-15);
  --sds-g-color-palette-yellow-30: #6f3400;
  --c360-g-color-palette-yellow-30: var(--sds-g-color-palette-yellow-30);
  --sds-g-color-palette-neutral-80: #c9c9c9;
  --c360-g-color-palette-neutral-80: var(--sds-g-color-palette-neutral-80);
  --sds-g-color-palette-pink-10: #370114;
  --c360-g-color-palette-pink-10: var(--sds-g-color-palette-pink-10);
  --sds-g-color-palette-orange-90: #fedfd0;
  --c360-g-color-palette-orange-90: var(--sds-g-color-palette-orange-90);
  --sds-g-color-palette-cloud-blue-50: #107cad;
  --c360-g-color-palette-cloud-blue-50: var(--sds-g-color-palette-cloud-blue-50);
  --sds-g-color-palette-pink-65: #fe7298;
  --c360-g-color-palette-pink-65: var(--sds-g-color-palette-pink-65);
  --sds-g-color-palette-indigo-50: #5867e8;
  --c360-g-color-palette-indigo-50: var(--sds-g-color-palette-indigo-50);
  --sds-g-color-palette-hot-orange-90: #ffded5;
  --c360-g-color-palette-hot-orange-90: var(--sds-g-color-palette-hot-orange-90);
  --sds-g-link-color-focus: #014486;
  --c360-g-link-color-focus: var(--sds-g-link-color-focus);
  --sds-g-color-palette-blue-15: #03234d;
  --c360-g-color-palette-blue-15: var(--sds-g-color-palette-blue-15);
  --sds-g-color-palette-yellow-20: #4f2100;
  --c360-g-color-palette-yellow-20: var(--sds-g-color-palette-yellow-20);
  --sds-g-color-palette-neutral-70: #aeaeae;
  --c360-g-color-palette-neutral-70: var(--sds-g-color-palette-neutral-70);
  --sds-g-color-palette-orange-80: #ffba90;
  --c360-g-color-palette-orange-80: var(--sds-g-color-palette-orange-80);
  --sds-g-color-palette-cloud-blue-40: #05628a;
  --c360-g-color-palette-cloud-blue-40: var(--sds-g-color-palette-cloud-blue-40);
  --sds-g-color-palette-indigo-40: #3a49da;
  --c360-g-color-palette-indigo-40: var(--sds-g-color-palette-indigo-40);
  --sds-g-color-palette-hot-orange-80: #feb9a5;
  --c360-g-color-palette-hot-orange-80: var(--sds-g-color-palette-hot-orange-80);
  --sds-g-color-palette-cloud-blue-95: #eaf5fe;
  --c360-g-color-palette-cloud-blue-95: var(--sds-g-color-palette-cloud-blue-95);
  --sds-g-color-palette-indigo-95: #f1f3fb;
  --c360-g-color-palette-indigo-95: var(--sds-g-color-palette-indigo-95);
  --sds-g-color-palette-red-15: #4a0c04;
  --c360-g-color-palette-red-15: var(--sds-g-color-palette-red-15);
  --sds-g-font-weight-bold: bold;
  --c360-g-font-weight-bold: var(--sds-g-font-weight-bold);
  --sds-g-color-palette-yellow-10: #281202;
  --c360-g-color-palette-yellow-10: var(--sds-g-color-palette-yellow-10);
  --sds-g-color-palette-neutral-60: #939393;
  --c360-g-color-palette-neutral-60: var(--sds-g-color-palette-neutral-60);
  --sds-g-color-palette-yellow-65: #d79304;
  --c360-g-color-palette-yellow-65: var(--sds-g-color-palette-yellow-65);
  --sds-g-color-palette-orange-70: #fe9339;
  --c360-g-color-palette-orange-70: var(--sds-g-color-palette-orange-70);
  --sds-g-color-palette-cloud-blue-30: #084968;
  --c360-g-color-palette-cloud-blue-30: var(--sds-g-color-palette-cloud-blue-30);
  --sds-g-color-palette-indigo-30: #2f2cb7;
  --c360-g-color-palette-indigo-30: var(--sds-g-color-palette-indigo-30);
  --sds-g-color-palette-hot-orange-70: #ff906e;
  --c360-g-color-palette-hot-orange-70: var(--sds-g-color-palette-hot-orange-70);
  --sds-g-color-border-inverse-1: #181818;
  --c360-g-color-border-inverse-1: var(--sds-g-color-border-inverse-1);
  --sds-g-color-palette-neutral-50: #747474;
  --c360-g-color-palette-neutral-50: var(--sds-g-color-palette-neutral-50);
  --sds-g-color-palette-orange-60: #dd7a01;
  --c360-g-color-palette-orange-60: var(--sds-g-color-palette-orange-60);
  --sds-g-color-palette-cloud-blue-20: #023248;
  --c360-g-color-palette-cloud-blue-20: var(--sds-g-color-palette-cloud-blue-20);
  --sds-g-color-palette-green-90: #cdefc4;
  --c360-g-color-palette-green-90: var(--sds-g-color-palette-green-90);
  --sds-g-color-palette-indigo-20: #260f8f;
  --c360-g-color-palette-indigo-20: var(--sds-g-color-palette-indigo-20);
  --sds-g-color-palette-hot-orange-60: #ff5d2d;
  --c360-g-color-palette-hot-orange-60: var(--sds-g-color-palette-hot-orange-60);
  --sds-g-color-palette-neutral-40: #5c5c5c;
  --c360-g-color-palette-neutral-40: var(--sds-g-color-palette-neutral-40);
  --sds-g-color-border-inverse-2: #2e2e2e;
  --c360-g-color-border-inverse-2: var(--sds-g-color-border-inverse-2);
  --sds-g-color-palette-orange-50: #a96404;
  --c360-g-color-palette-orange-50: var(--sds-g-color-palette-orange-50);
  --sds-g-color-palette-cloud-blue-10: #001a28;
  --c360-g-color-palette-cloud-blue-10: var(--sds-g-color-palette-cloud-blue-10);
  --sds-g-color-palette-neutral-95: #f3f3f3;
  --c360-g-color-palette-neutral-95: var(--sds-g-color-palette-neutral-95);
  --sds-g-color-palette-green-80: #91db8b;
  --c360-g-color-palette-green-80: var(--sds-g-color-palette-green-80);
  --sds-g-color-palette-indigo-10: #200647;
  --c360-g-color-palette-indigo-10: var(--sds-g-color-palette-indigo-10);
  --sds-g-color-palette-hot-orange-50: #d83a00;
  --c360-g-color-palette-hot-orange-50: var(--sds-g-color-palette-hot-orange-50);
  --sds-g-color-palette-cloud-blue-65: #08abed;
  --c360-g-color-palette-cloud-blue-65: var(--sds-g-color-palette-cloud-blue-65);
  --sds-g-color-palette-indigo-65: #8e9bef;
  --c360-g-color-palette-indigo-65: var(--sds-g-color-palette-indigo-65);
  --sds-g-link-color-hover: #014486;
  --c360-g-link-color-hover: var(--sds-g-link-color-hover);
  --sds-g-font-size-base: 16px;
  --sds-g-color-palette-neutral-30: #444444;
  --c360-g-color-palette-neutral-30: var(--sds-g-color-palette-neutral-30);
  --sds-g-color-palette-orange-40: #825101;
  --c360-g-color-palette-orange-40: var(--sds-g-color-palette-orange-40);
  --sds-g-color-palette-pink-15: #4b0620;
  --c360-g-color-palette-pink-15: var(--sds-g-color-palette-pink-15);
  --sds-g-color-palette-green-70: #45c65a;
  --c360-g-color-palette-green-70: var(--sds-g-color-palette-green-70);
  --sds-g-color-palette-hot-orange-40: #aa3001;
  --c360-g-color-palette-hot-orange-40: var(--sds-g-color-palette-hot-orange-40);
  --sds-g-color-palette-orange-95: #fff1ea;
  --c360-g-color-palette-orange-95: var(--sds-g-color-palette-orange-95);
  --sds-g-color-palette-hot-orange-95: #fef1ed;
  --c360-g-color-palette-hot-orange-95: var(--sds-g-color-palette-hot-orange-95);
  --sds-g-sizing-border-1: 1px;
  --c360-g-sizing-border-1: var(--sds-g-sizing-border-1);
  --c360-g-font-family-display: 'ITC Avant Garde', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --c360-g-font-family-sans: 'Salesforce Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --c360-g-font-size-1: 12px;
  --c360-g-font-size-2: 14px;
  --c360-g-font-size-3: 16px;
  --c360-g-font-size-4: 20px;
  --c360-g-font-size-5: 24px;
  --c360-g-font-size-6: 32px;
  --c360-g-font-size-7: 40px;
  --c360-g-font-size-8: 48px;
  --c360-g-font-size-9: 56px;
  --c360-g-font-size-10: 80px;
}

@font-face {
  font-family: 'Salesforce Sans';
  src: url('https://a.sfdcstatic.com/shared/fonts/salesforce-sans/SalesforceSans-Regular.woff2') format('woff2'),
    url('https://a.sfdcstatic.com/shared/fonts/salesforce-sans/SalesforceSans-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Salesforce Sans';
  src: url('https://a.sfdcstatic.com/shared/fonts/salesforce-sans/SalesforceSans-Bold.woff2') format('woff2'),
    url('https://a.sfdcstatic.com/shared/fonts/salesforce-sans/SalesforceSans-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Avant Garde';
  src: url('https://a.sfdcstatic.com/shared/fonts/avant-garde/AvantGardeForSalesforceW05-Dm.woff2') format('woff2'), url('https://a.sfdcstatic.com/shared/fonts/avant-garde/AvantGardeForSalesforceW05-Dm.woff') format('woff');
  font-weight: 600;
  font-display: swap;
}

[type-style='display-1'] {
  font-family: var(--c360-g-font-family-display);
  font-size: var(--c360-g-font-size-10);
  line-height: 88px;
  letter-spacing: -1.8px;
  color: var(--sds-g-color-brand-inverse-2);
}

[type-style='display-2'] {
  font-family: var(--c360-g-font-family-display);
  font-size: var(--c360-g-font-size-9);
  line-height: 64px;
  letter-spacing: -1.2px;
  color: var(--sds-g-color-brand-inverse-2);
}

[type-style='display-3'] {
  font-family: var(--c360-g-font-family-display);
  font-size: var(--c360-g-font-size-8);
  line-height: 56px;
  letter-spacing: -1px;
  color: var(--sds-g-color-brand-inverse-2);
}

[type-style='display-4'] {
  font-family: var(--c360-g-font-family-display);
  font-size: var(--c360-g-font-size-7);
  line-height: 48px;
  letter-spacing: -0.85px;
  color: var(--sds-g-color-brand-inverse-2);
}

[type-style='display-5'] {
  font-family: var(--c360-g-font-family-display);
  font-size: var(--c360-g-font-size-6);
  line-height: 40px;
  letter-spacing: -0.6px;
  color: var(--sds-g-color-brand-inverse-2);
}

[type-style='display-6'] {
  font-family: var(--c360-g-font-family-display);
  font-size: var(--c360-g-font-size-5);
  line-height: 32px;
  letter-spacing: -0.5px;
  color: var(--sds-g-color-brand-inverse-2);
}

[type-style='display-7'] {
  font-family: var(--c360-g-font-family-display);
  font-size: var(--c360-g-font-size-4);
  line-height: 28px;
  letter-spacing: -0.5px;
  color: var(--sds-g-color-brand-inverse-2);
}

[type-style='display-8'] {
  font-family: var(--c360-g-font-family-display);
  font-size: var(--c360-g-font-size-3);
  line-height: 24px;
  letter-spacing: 0;
  color: var(--sds-g-color-brand-inverse-2);
}

[type-style='body-1'] {
  font-family: var(--c360-g-font-family-sans);
  font-weight: 400;
  font-size: var(--c360-g-font-size-4);
  line-height: 32px;
  letter-spacing: 0;
  color: var(--sds-g-color-neutral-inverse-1);
}

[type-style='body-2'] {
  font-family: var(--c360-g-font-family-sans);
  font-weight: 400;
  font-size: var(--c360-g-font-size-3);
  line-height: 24px;
  letter-spacing: 0;
  color: var(--sds-g-color-neutral-inverse-1);
}

[type-style='body-3'] {
  font-family: var(--c360-g-font-family-sans);
  font-weight: 400;
  font-size: var(--c360-g-font-size-2);
  line-height: 20px;
  letter-spacing: 0;
  color: var(--sds-g-color-neutral-inverse-1);
}

[type-style='eyebrow'] {
  font-family: var(--c360-g-font-family-sans);
  font-weight: 700;
  font-size: var(--c360-g-font-size-1);
  line-height: 24px;
  letter-spacing: 0;
  color: var(--sds-g-color-brand-inverse-2);
}

[type-style='caption'] {
  font-family: var(--c360-g-font-family-sans);
  font-weight: 400;
  font-size: var(--c360-g-font-size-1);
  line-height: 20px;
  letter-spacing: 0;
  color: var(--sds-g-color-neutral-inverse-1);
}

[type-style='body-1'] [type-style='code'],
[type-style='body-1'] code,
[type-style='body-1'] a {
  font-family: var(--c360-g-font-family-monospace);
  font-weight: 400;
  font-size: var(--c360-g-font-size-4);
  line-height: 32px;
  letter-spacing: 0;
  color: var(--sds-g-color-neutral-inverse-1);
}

[type-style='body-2'] [type-style='code'],
[type-style='body-2'] code,
[type-style='body-2'] a {
  font-family: var(--c360-g-font-family-monospace);
  font-weight: 400;
  font-size: var(--c360-g-font-size-3);
  line-height: 24px;
  letter-spacing: 0;
  color: var(--sds-g-color-neutral-inverse-1);
}

[type-style='body-3'] [type-style='code'],
[type-style='body-3'] code,
[type-style='body-3'] a {
  font-family: var(--c360-g-font-family-monospace);
  font-weight: 400;
  font-size: var(--c360-g-font-size-2);
  line-height: 20px;
  letter-spacing: 0;
  color: var(--sds-g-color-neutral-inverse-1);
}

[type-style='caption'] [type-style='code'],
[type-style='caption'] code,
[type-style='caption'] a {
  font-family: var(--c360-g-font-family-monospace);
  font-weight: 400;
  font-size: var(--c360-g-font-size-1);
  line-height: 20px;
  letter-spacing: 0;
  color: var(--sds-g-color-neutral-inverse-1);
}

[type-style='body-1'] a,
[type-style='body-2'] a,
[type-style='body-3'] a,
[type-style='caption'] a {
  color: var(--sds-g-color-brand-base-contrast-2);
}

[type-style='body-1'] a:hover,
[type-style='body-2'] a:hover,
[type-style='body-3'] a:hover,
[type-style='caption'] a:hover {
  color: var(--sds-g-color-brand-inverse-2);
}

[util-style='ellipsis'] {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[util-style='multi-line-ellipsis'] {
  display: -webkit-box;
  -webkit-line-clamp: var(--c360-g-text-ellipsis-lines, 3);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

:host(:focus) {
  outline: 0;
}

[part~='button'] {
  display: var(--sds-c-button-display, inline-flex);
  position: relative;
  background: none;
  background-color: var(--sds-c-button-color-background, var(--sds-s-button-color-background, transparent));
  background-clip: border-box;
  color: var(--sds-c-button-text-color, var(--sds-s-button-text-color, inherit));
  padding-left: var(--sds-c-button-spacing-inline-start,
      var(--sds-c-button-spacing-inline, var(--sds-s-button-spacing-inline)));
  padding-right: var(--sds-c-button-spacing-inline-end,
      var(--sds-c-button-spacing-inline, var(--sds-s-button-spacing-inline)));
  padding-top: var(--sds-c-button-spacing-block-start,
      var(--sds-c-button-spacing-block, var(--sds-s-button-spacing-block)));
  padding-bottom: var(--sds-c-button-spacing-block-start,
      var(--sds-c-button-spacing-block, var(--sds-s-button-spacing-block)));
  border-width: var(--sds-c-button-sizing-border, var(--sds-s-button-sizing-border, 1px));
  border-style: solid;
  border-color: var(--sds-c-button-color-border, var(--sds-s-button-color-border, transparent));
  border-radius: var(--sds-c-button-radius-border, var(--sds-c-button-radius-border, 4px));
  box-shadow: var(--sds-c-button-shadow, var(--sds-s-button-shadow));
  width: var(--sds-c-button-width);
  line-height: var(--sds-c-button-line-height);
  white-space: normal;
  user-select: none;
  align-items: center;
  text-decoration: var(--sds-c-button-text-decoration, none);
  appearance: none;
}

[part~='button']:hover,
[part~='button']:focus {
  --sds-c-button-text-color: var(--sds-c-button-text-color-hover,
      var(--sds-s-button-text-color-hover, #0176d3));
  --sds-c-button-color-background: var(--sds-c-button-color-background-hover,
      var(--sds-s-button-color-background-hover));
  --sds-c-button-color-border: var(--sds-c-button-color-border-hover, var(--sds-s-button-color-border-hover));
  cursor: pointer;
}

[part~='button']:focus {
  --sds-c-button-color-background: var(--sds-c-button-color-background-focus,
      var(--sds-s-button-color-background-focus));
  --sds-c-button-color-border: var(--sds-c-button-color-border-focus,
      var(--sds-s-button-color-border-focus, #0176d3));
  --sds-c-button-shadow: var(--sds-c-button-shadow-focus, var(--sds-s-button-shadow-focus, #0176d3 0 0 3px));
  outline: 0;
}

[part~='button']:active {
  --sds-c-button-text-color: var(--sds-c-button-text-color-active,
      var(--sds-s-button-text-color-active, currentColor));
  --sds-c-button-color-background: var(--sds-c-button-color-background-active,
      var(--sds-s-button-color-background-active));
  --sds-c-button-color-border: var(--sds-c-button-color-border-active,
      var(--sds-s-button-color-border-active, #0176d3));
}

[part~='button']:disabled {
  --sds-c-button-text-color: var(--sds-c-button-text-color-disabled, #939393);
  --sds-c-button-color-background: var(--sds-c-button-color-background-disabled);
  --sds-c-button-color-border: var(--sds-c-button-color-border-disabled);
}

[part~='button']:disabled * {
  pointer-events: none;
}

:host {
  font-size: var(--c360-g-font-size-3);
  font-family: var(--c360-g-font-family-sans);
  font-weight: var(--c360-g-font-weight-bold);
  --sds-c-button-spacing-block: var(--c360-g-spacing-2);
  --sds-c-button-spacing-inline: var(--c360-g-spacing-5);
  --sds-c-button-radius-border: var(--c360-g-radius-border-2);
  --sds-c-button-text-color-disabled: var(--c360-g-color-brand-inverse-contrast-4);
  --sds-c-button-color-background-disabled: var(--c360-g-color-palette-neutral-65);
  --sds-c-button-color-border-disabled: var(--c360-g-color-palette-neutral-65);
  --sds-c-button-sizing-border: var(--c360-g-radius-border-1);
  --sds-c-icon-sizing-width: 16px;
  --sds-c-icon-sizing-border: 0;
  --c360-g-kx-speed-multiplier-value: 1;
  --c360-g-kx-reduced-motion-multiplier-os: 1;
  --c360-g-kx-reduced-motion-multiplier-simulation: 1;
  --c360-g-kx-reduced-motion-multiplier: calc(var(--c360-g-kx-reduced-motion-multiplier-os) * var(--c360-g-kx-reduced-motion-multiplier-simulation));
  --c360-g-kx-duration-x-long-init: 600ms;
  --c360-g-kx-duration-long-init: 400ms;
  --c360-g-kx-duration-normal-init: 250ms;
  --c360-g-kx-duration-short-init: 150ms;
  --c360-g-kx-duration-x-short-init: 75ms;
  --c360-g-kx-duration-x-long-base: var(--c360-g-kx-duration-x-long-init);
  --c360-g-kx-duration-long-base: var(--c360-g-kx-duration-long-init);
  --c360-g-kx-duration-normal-base: var(--c360-g-kx-duration-normal-init);
  --c360-g-kx-duration-short-base: var(--c360-g-kx-duration-short-init);
  --c360-g-kx-duration-x-short-base: var(--c360-g-kx-duration-x-short-init);
  --c360-g-kx-duration-x-long: calc(var(--c360-g-kx-reduced-motion-multiplier) * var(--c360-g-kx-duration-x-long-base) / var(--c360-g-kx-speed-multiplier-value));
  --c360-g-kx-duration-long: calc(var(--c360-g-kx-reduced-motion-multiplier) * var(--c360-g-kx-duration-long-base) / var(--c360-g-kx-speed-multiplier-value));
  --c360-g-kx-duration-normal: calc(var(--c360-g-kx-reduced-motion-multiplier) * var(--c360-g-kx-duration-normal-base) / var(--c360-g-kx-speed-multiplier-value));
  --c360-g-kx-duration-short: calc(var(--c360-g-kx-reduced-motion-multiplier) * var(--c360-g-kx-duration-short-base) / var(--c360-g-kx-speed-multiplier-value));
  --c360-g-kx-duration-x-short: calc(var(--c360-g-kx-reduced-motion-multiplier) * var(--c360-g-kx-duration-x-short-base) / var(--c360-g-kx-speed-multiplier-value));
  --c360-g-kx-ease-none-init: cubic-bezier(0, 0, 1, 1);
  --c360-g-kx-ease-in-init: cubic-bezier(0.3, 0, 1, 0.3);
  --c360-g-kx-ease-out-init: cubic-bezier(0, 0.3, 0.15, 1);
  --c360-g-kx-ease-in-out-init: cubic-bezier(0.3, 0, 0.15, 1);
  --c360-g-kx-ease-under-init: cubic-bezier(0.7, 0, 0.7, -0.75);
  --c360-g-kx-ease-over-init: cubic-bezier(0.3, 1.75, 0.3, 1);
  --c360-g-kx-ease-none: var(--c360-g-kx-ease-none-init);
  --c360-g-kx-ease-in: var(--c360-g-kx-ease-in-init);
  --c360-g-kx-ease-out: var(--c360-g-kx-ease-out-init);
  --c360-g-kx-ease-in-out: var(--c360-g-kx-ease-in-out-init);
  --c360-g-kx-ease-under: var(--c360-g-kx-ease-under-init);
  --c360-g-kx-ease-over: var(--c360-g-kx-ease-over-init);
}

:host([variant='primary']) {
  --sds-c-button-color-background: var(--c360-g-color-palette-blue-50);
  --sds-c-button-color-border: var(--c360-g-color-palette-blue-50);
  --sds-c-button-text-color: var(--c360-g-color-brand-inverse-contrast-4);
  --sds-c-button-color-background-hover: var(--c360-g-color-palette-cloud-blue-20);
  --sds-c-button-color-border-hover: var(--c360-g-color-palette-cloud-blue-20);
  --sds-c-button-text-color-hover: var(--c360-g-color-brand-inverse-contrast-4);
  --sds-c-button-color-background-active: var(--c360-g-color-palette-cloud-blue-20);
  --sds-c-button-color-border-active: var(--c360-g-color-palette-cloud-blue-20);
  --sds-c-button-text-color-active: var(--c360-g-color-brand-inverse-contrast-4);
  --sds-c-button-color-background-focus: var(--c360-g-color-palette-cloud-blue-20);
  --sds-c-button-color-border-focus: var(--c360-g-color-palette-cloud-blue-20);
}

:host [part~='button']:focus {
  outline: 5px solid var(--c360-g-color-brand-base-contrast-1);
}

:host([variant='secondary']) {
  --sds-c-button-color-background: transparent;
  --sds-c-button-color-border: var(--c360-g-color-palette-blue-50);
  --sds-c-button-text-color: var(--c360-g-color-palette-blue-50);
  --sds-c-button-color-background-hover: var(--c360-g-color-palette-cloud-blue-95);
  --sds-c-button-color-border-hover: var(--c360-g-color-palette-cloud-blue-20);
  --sds-c-button-text-color-hover: var(--c360-g-color-palette-cloud-blue-20);
  --sds-c-button-color-background-active: var(--c360-g-color-palette-cloud-blue-95);
  --sds-c-button-color-border-active: var(--c360-g-color-palette-cloud-blue-20);
  --sds-c-button-text-color-active: var(--c360-g-color-palette-cloud-blue-20);
  --sds-c-button-color-background-focus: var(--c360-g-color-palette-cloud-blue-95);
  --sds-c-button-color-border-focus: var(--c360-g-color-palette-cloud-blue-20);
}

:host([variant='tertiary']) {
  --sds-c-button-text-color: var(--c360-g-color-palette-blue-50);
  --sds-c-button-color-background-hover: var(--c360-g-color-palette-cloud-blue-95);
  --sds-c-button-color-border-hover: transparent;
  --sds-c-button-text-color-hover: var(--c360-g-color-palette-blue-30);
  --sds-c-button-color-background-active: var(--c360-g-color-palette-cloud-blue-95);
  --sds-c-button-color-border-active: transparent;
  --sds-c-button-text-color-active: var(--c360-g-color-palette-blue-30);
  --sds-c-button-color-background-focus: var(--c360-g-color-palette-cloud-blue-95);
  --sds-c-button-color-border-focus: transparent;
}

:host([size='hero']) {
  font-size: var(--c360-g-font-size-3);
  --sds-c-button-spacing-block: var(--c360-g-spacing-3);
  --sds-c-button-spacing-inline: var(--c360-g-spacing-6);
}

:host([size='small']) {
  font-size: var(--c360-g-font-size-2);
  --sds-c-button-spacing-block: var(--c360-g-spacing-1);
  --sds-c-button-spacing-inline: var(--c360-g-spacing-3);
  --sds-c-icon-sizing-width: 12px;
  --sds-c-button-line-height: 1.15;
}

:host([size='small']) [icon-only] {
  --sds-c-button-spacing-block: var(--c360-g-spacing-2);
  --sds-c-button-spacing-inline: var(--c360-g-spacing-2);
}

:host([size='default']) [icon-only] {
  --sds-c-button-spacing-block: var(--c360-g-spacing-3);
  --sds-c-button-spacing-inline: var(--c360-g-spacing-3);
}

:host([size='hero']) [icon-only] {
  --sds-c-button-spacing-block: var(--c360-g-spacing-4);
  --sds-c-button-spacing-inline: var(--c360-g-spacing-4);
}

[part~='button'] {
  max-width: 100%;
}

::slotted([slot='end']) {
  margin-inline-start: var(--c360-g-spacing-2);
}

[icon-only] ::slotted([slot='end']) {
  margin-inline-start: 0;
}

::slotted([slot='start']) {
  margin-inline-end: var(--c360-g-spacing-2);
}

[icon-only] ::slotted([slot='start']) {
  margin-inline-end: 0;
}

:host([kx-type='ripple']) {
  --c360-c-button-kx-ripple-size: 20px;
  --c360-c-button-kx-pointer-position: 50%;
}

:host([kx-type='ripple']) [part='button'] {
  transition: 0.08s all ease-out;
}

:host([kx-type='ripple']) [part='button']:active {
  transform: scale(0.95);
}

:host([variant='tertiary'][kx-type='ripple']) [part='button']::before,
:host([kx-type='ripple']) [part='button'] [part='kx-ripple'] {
  position: absolute;
  border-radius: var(--sds-c-button-radius-border);
  top: calc(-1 * var(--sds-c-button-sizing-border));
  left: calc(-1 * var(--sds-c-button-sizing-border));
  width: calc(100% + var(--sds-c-button-sizing-border) * 2);
  height: calc(100% + var(--sds-c-button-sizing-border) * 2);
}

:host([kx-type='ripple']) [part='kx-ripple']::after {
  content: '';
  position: absolute;
  width: var(--c360-c-button-kx-ripple-size);
  height: var(--c360-c-button-kx-ripple-size);
  top: 0;
  left: 0;
  border-radius: 50%;
  opacity: 0;
  background-color: var(--c360-g-color-brand-inverse-contrast-4);
}

:host([kx-type='ripple']) [part='kx-ripple'] {
  overflow: hidden;
}

:host([variant='secondary'][kx-type='ripple']) [part='kx-ripple']::after,
:host([variant='tertiary'][kx-type='ripple']) [part='kx-ripple']::after {
  background-color: var(--c360-g-color-palette-cloud-blue-50);
}

:host([variant='tertiary'][kx-type='ripple']) [part='button']::before {
  content: '';
  z-index: -1;
  transform: scale(0.9);
  transition: 0.1s transform ease-out;
}

:host([variant='tertiary'][kx-type='ripple']) [part='button']:hover::before {
  background-color: var(--c360-g-color-brand-base-3);
  transform: scale(1);
}

:host([kx-type='ripple']) [part='button'].sds-kx-is-animating-from-click [part='kx-ripple']::after {
  top: calc(var(--c360-c-button-kx-pointer-position-y, var(--c360-c-button-kx-pointer-position)) - var(--c360-c-button-kx-ripple-size, var(--c360-c-button-kx-ripple-size)) / 2);
  left: calc(var(--c360-c-button-kx-pointer-position-x, var(--c360-c-button-kx-pointer-position)) - var(--c360-c-button-kx-ripple-size, var(--c360-c-button-kx-ripple-size)) / 2);
  animation: c360-kx-button_ripple var(--c360-g-kx-duration-normal) cubic-bezier(0.35, 0.01, 0.62, 0.99);
}

@media (prefers-reduced-motion: reduce) {
  :host([kx-type='ripple']) [part='button'].sds-kx-is-animating-from-click [part='kx-ripple']::after {
    animation: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  [kx-scope^='button-'][kx-type='ripple']:focus {
    transition: none;
  }
}

@media (hover: none) {
  :host([kx-type='ripple']) [part='button']:active {
    transform: scale(0.97);
  }
}

@media (prefers-reduced-motion: reduce) {
  :host([kx-type='ripple']) [part='button']:active {
    transform: none;
  }
}

@media (hover: hover) and (pointer: fine) {
  :host([kx-type='ripple']) [part='button']:hover {
    transform: scale(1);
  }

  :host([kx-type='ripple']) [part='button']:active {
    transform: scale(0.97);
  }
}

@media (prefers-reduced-motion: reduce) {
  :host([kx-type='ripple']) [part='button']:hover {
    transform: none;
  }
}

@keyframes c360-kx-button_ripple {
  0% {
    opacity: 0.35;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(7);
  }
}

:host {
  --nav-integration-c-header-height-mobile: 56px;
  --nav-integration-c-header-height-desktop: 96px;
}

:host {
  --c360-nav-c-logo-height: 48.03px;
  --c360-nav-c-logo-width: 68px;
}

.obscured-panel {
  display: none;
  visibility: hidden;
}

a:focus-visible,
button:focus-visible {
  outline: 5px solid var(--sds-g-color-brand-base-contrast-1);
}

.c360-nav {
  --c360-nav-c-header-height: 56px;
  --c360-nav-c-overlay-z-index: 400;
  --c360-nav-c-padding-top: 138px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 20000;
}

.c360-spacer {
  padding-top: var(--nav-integration-c-header-height-mobile);
}

.c360-spacer-hidden {
  padding: 0 !important;
}

a img.icon-new-window {
  margin-left: 7px;
}

.buttonlink-newwin {
  margin: 0 0 2px 7px;
}

li.active-l2 img.buttonlink-newwin {
  content: url('https://a.sfdcstatic.com/shared/images/c360-nav/link.svg');
}

.c360-nav__header {
  display: block;
  justify-content: initial;
  margin: 0px var(--sds-g-spacing-5);
}

.c360-nav__header--mobile {
  height: var(--nav-integration-c-header-height-mobile);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.c360-nav__overlay {
  content: '';
  display: block;
  position: fixed;
  top: var(--nav-integration-c-header-height-mobile);
  height: calc(100vh - var(--nav-integration-c-header-height-mobile));
  height: calc((var(--vh, 1vh) * 100) - var(--nav-integration-c-header-height-mobile));
  bottom: 0;
  left: 0;
  z-index: var(--c360-nav-c-overlay-z-index);
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}

.c360-nav__overlay--active {
  opacity: 1;
}

.c360-nav__nav-items {
  display: none;
  height: calc(100vh - var(--nav-integration-c-header-height-mobile));
  height: calc((var(--vh, 1vh) * 100) - var(--nav-integration-c-header-height-mobile));
  background-color: #fff;
}

.c360-nav__nav-items--l2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.c360-nav__nav-items--l2 .l2-container {
  margin-left: var(--sds-g-spacing-5);
}

.c360-nav__nav-items-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.c360-nav__nav-items-list--l1 {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.c360-nav__nav-items-list--l2 {
  padding-bottom: 40px;
}

.nav-item-label--l2--container,
.nav-item-chevron--l2--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c360-nav__nav-items-list--l2 .icons {
  height: 32px;
  width: 32px;
  min-width: 32px;
  background: #ffffff;
  box-shadow: 4px 8px 15px rgba(24, 24, 24, 0.12);
  border-radius: 100px;
  margin-left: 0px;
  margin-right: 16px;
  z-index: 2;
}

.c360-nav__nav-items-list--l2 .icons img {
  max-width: 24px;
  margin: 6px;
}

.c360-nav__nav-item.c360-nav__nav-item--l2.waterfall-offset.waterfall-show {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.l2-badge {
  margin-left: 10px;
  display: flex;
}

.l2-icon.arrow {
  height: 100%;
  min-width: 18px;
}

span.nav-item-label--l2 {
  display: flex;
  align-items: center;
}

span.nav-item-label--l2 a {
  text-decoration: none;
  color: var(--sds-c-button-text-color,
      var(--sds-s-button-text-color, inherit));
}

.c360-nav__nav-item--l2-background {
  visibility: none;
}

.c360-nav__nav-item-h3 {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: normal;
  font-size: initial;
}

.c360-nav__header-logo {
  display: none;
}

.c360-nav__header-logo-img {
  width: var(--c360-nav-c-logo-width);
  height: var(--c360-nav-c-logo-height);
}

.container-hamburger hgf-button::part(button) {
  margin-right: 24px;
}

.mobile-logo {
  max-height: 32px;
}

.mobile-logo img {
  max-width: 45px;
}

.hamburger-img {
  display: none;
}

.close-mobile-img {
  display: none;
}

.hamburger-img.show,
.close-mobile-img.show {
  display: block;
}

.mobile-search {
  margin-left: auto;
}

.mobile-search .mobile-utility-button::part(button) {
  line-height: 10px;
}

.mobile-login {
  margin-left: 9px;
  margin-right: var(--sds-g-spacing-4);
}

.c360-nav__header--mobile div:last-child {
  margin-right: var(--sds-g-spacing-0);
}

.c360-nav__header--mobile div:last-child:is(.mobile-login) {
  position: relative;
  margin-right: -8px;
}

.utility-nav {
  display: none;
}

.c360-nav__nav-items.show {
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: var(--nav-integration-c-header-height-mobile);
  left: 0;
  width: 100vw;
  z-index: calc(var(--c360-nav-c-overlay-z-index) + 1);
}

.c360-nav__nav-items.show .c360-nav__nav-items-list--l1 .c360-nav__nav-item--l1:last-child {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
}

.c360-nav__nav-items.show--submenu {
  overflow: hidden;
}

.l1-button {
  --sds-c-button-color-background: transparent;
  --sds-c-button-color-background-hover: transparent;
  --sds-c-button-color-background-active: transparent;
  --sds-c-button-text-color: #032d60;
  --sds-c-button-text-color-active: #0176d3;
  --sds-c-button-spacing-inline-start: 0;
  --sds-c-button-spacing-inline-end: 0;
  --sds-c-button-color-border: transparent;
  --sds-c-button-color-border-active: transparent;
  --sds-c-button-color-border-focus: transparent;
  --sds-c-button-sizing-border: 0px;
  --sds-c-button-shadow: none;
  --sds-c-button-width: 100%;
}

.l1-button::part(button) {
  font-family: var(--c360-g-font-family-display);
  font-weight: normal;
}

.l2-button {
  --sds-c-button-color-background: transparent;
  --sds-c-button-color-background-hover: transparent;
  --sds-c-button-color-background-active: transparent;
  --sds-c-button-text-color: #032d60;
  --sds-c-button-spacing-inline-start: 0;
  --sds-c-button-spacing-inline-end: 0;
  --sds-c-button-color-border: transparent;
  --sds-c-button-color-border-active: transparent;
  --sds-c-button-color-border-focus: transparent;
  --sds-c-button-sizing-border: 0px;
  --sds-c-button-shadow: none;
  --sds-c-button-width: 100%;
  width: 100%;
}

.l2-hybrid-chevron {
  width: auto;
  float: right;
}

.utility-button {
  --sds-c-button-spacing-inline-start: 10px;
  --sds-c-button-spacing-inline-end: 10px;
  --sds-c-button-color-background: transparent;
  --sds-c-button-color-background-hover: #eaf5fe;
  --sds-c-button-color-background-active: transparent;
  --sds-c-button-sizing-border: 0px;
  --sds-c-button-shadow: none;
  --sds-c-button-radius-border: 100px;
}

.mobile-utility-button {
  --sds-c-button-spacing-inline-start: 0;
  --sds-c-button-spacing-inline-end: 0;
  --sds-c-button-color-background: transparent;
  --sds-c-button-color-background-hover: transparent;
  --sds-c-button-color-background-active: transparent;
  --sds-c-button-sizing-border: 0px;
  --sds-c-button-shadow: none;
  --sds-c-button-radius-border: 100px;
}

.utility-button img {
  position: relative;
  top: 3px;
  max-width: 18px;
}

.icon {
  width: 18px;
  height: 18px;
}

.icon-search--hover {
  display: none;
}

.utility-button:hover .icon-search {
  display: none;
}

.utility-button:hover .icon-search--hover {
  display: inline;
}

.utility-button:focus .icon-search {
  display: none;
}

.utility-button:focus .icon-search--hover {
  display: inline;
}

.utility-button .btn-icon {
  margin-right: 3px;
}

.c360-nav__nav-item {
  margin-top: var(--sds-g-spacing-3);
  margin-right: var(--sds-g-spacing-6);
}

.c360-nav__nav-item--l1 {
  margin-left: var(--sds-g-spacing-5);
  margin-right: var(--sds-g-spacing-5);
}

.c360-nav__nav-item--l1.active-l1 .c360-nav__nav-item-h3 .l1-button {
  --sds-c-button-text-color: #0176d3;
  --sds-c-button-text-color-hover: #0176d3;
}

.c360-nav__nav-item--l2 {
  margin-top: 8px;
}

.c360-nav__nav-items-list--l1 li .l1-button span,
.c360-nav__nav-items-list--l2 li .l2-button span {
  float: left;
}

.c360-nav__nav-items-list--l1 li .l1-button span.arrow,
.c360-nav__nav-items-list--l2 li .l2-button span.arrow {
  float: right;
}

.c360-nav__nav-items-list--l1 li .l1-button span.arrow::before,
.c360-nav__nav-items-list--l2 li .l2-button span.arrow::before {
  content: url('https://a.sfdcstatic.com/shared/images/c360-nav/icons.svg#chevron-right-gray');
}

.c360-nav__nav-items-list--l2 li .l2-button span {
  font-size: 16px;
}

.c360-nav__nav-panel {
  position: absolute;
  left: 0;
  top: 0;
  z-index: calc(var(--c360-nav-c-overlay-z-index) + 1);
  width: 100%;
  background-color: #fff;
  height: calc(100vh - var(--nav-integration-c-header-height-mobile));
  height: calc((var(--vh, 1vh) * 100) - var(--nav-integration-c-header-height-mobile));
  transition: none;
  transform: translateX(-100%);
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;
}

.c360-nav__nav-items-list--l1 li.active-l1 .c360-nav__nav-panel {
  transform: translateX(0);
  visibility: visible;
  display: flex;
}

.l2-panel-back>a,
.l3-panel-back>a,
.c360-nav__nav-items--l2 .links>a,
.utility-menu .links>a {
  color: #032d60;
  text-decoration: none;
}

.c360-nav__nav-items--l2 h2 {
  font-family: var(--c360-g-font-family-display);
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #032d60;
  margin-bottom: 24px;
  margin-top: 30px;
}

.l2-button::part(button) {
  font-family: var(--c360-g-font-family-display);
  font-weight: normal;
}

.l2-hybrid-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.l2-hybrid-label {
  float: left;
}

.c360-nav__nav-items--l2 .links h3,
.utility-menu>h3 {
  font-size: 20px;
  color: #032d60;
  line-height: 28px;
}

.c360-nav__nav-items--l2 .links h4,
.utility-menu .links h4 {
  margin: 16px auto;
}

.c360-nav__nav-items--l2 .links h5,
.utility-menu .links h5 {
  font-size: 14px;
  color: #444444;
  font-weight: 500;
  line-height: 20px;
  margin: 16px auto;
}

.c360-nav__nav-items--l2 .links>a img,
.utility-menu .links>a img {
  margin: 0px 3px;
}

.c360-nav__nav-items--l2 .c360-nav__nav-items-list--l2 li>a {
  font-weight: 700;
  color: #032d60;
  font-size: 14px;
  text-decoration: underline;
}

.c360-nav__nav-items--l2 .c360-nav__nav-items-list--l2 li>a:hover,
.c360-nav__nav-items--l2 .c360-nav__nav-items-list--l2 li>a:focus {
  color: var(--sds-g-color-palette-blue-50, '#0176d3');
}

.c360-nav__l2-panel-message {
  display: none;
}

.c360-nav__l2-panel-message--mobile {
  background-color: #eaf5fe;
}

.c360-nav__l3-panel {
  overflow-y: auto;
  padding-top: 0;
  transform: translateX(-100%);
  position: fixed;
  width: 100%;
  height: calc(100vh - var(--nav-integration-c-header-height-mobile));
  height: calc((var(--vh, 1vh) * 100) - var(--nav-integration-c-header-height-mobile));
  right: 0;
  top: 0;
  background-color: #fff;
  display: none;
  z-index: 10;
}

.c360-nav__nav-items-list--l2 li.active-l2 .c360-nav__l3-panel {
  transform: translateX(0);
  visibility: visible;
  display: inherit;
}

.c360-nav__l3-panel-inner-title {
  font-family: var(--c360-g-font-family-display);
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}

.c360-nav__l3-panel-inner {
  margin-left: var(--sds-g-spacing-5);
  margin-right: var(--sds-g-spacing-5);
}

.c360-nav__nav-items-list--l1 li .l1-button span {
  font-size: 24px;
  line-height: 32px;
  float: left;
}

.c360-nav__nav-items-list--l1 li .l1-button.login span {
  font-size: 16px;
}

.nav-item-label--l1,
.nav-item-label--l2 {
  position: relative;
  text-align: left;
  max-width: 80%;
}

.l2-panel-back,
.l3-panel-back {
  font-family: var(--c360-g-font-family-display);
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-top: 26px;
}

.l2-panel-back>a::before,
.l3-panel-back>a::before {
  background-image: url(https://a.sfdcstatic.com/shared/images/c360-nav/icons.svg#chevron-left-blue);
  color: #032d60;
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-position-y: 4px;
  background-repeat: no-repeat;
}

.utility-items {
  display: flex;
  height: 100%;
  flex-direction: column;
  row-gap: 8px;
}

@media (min-width: 768px) and (max-width: 1279px) {
  .c360-nav__nav-items.show {
    width: 50%;
  }

  .c360-nav__header {
    margin: 0px var(--sds-g-spacing-6);
  }

  .c360-nav__l3-panel-inner {
    margin-left: var(--sds-g-spacing-6);
    margin-right: var(--sds-g-spacing-6);
  }

  .c360-nav__nav-items--l2 .l2-container {
    margin-left: var(--sds-g-spacing-6);
  }

  .c360-nav__nav-item--l1 {
    margin-left: var(--sds-g-spacing-6);
    margin-right: var(--sds-g-spacing-6);
  }
}

@media (min-width: 1280px) {
  .c360-nav__nav-items-list--l1-overflow {
    list-style: none;
  }

  .c360-nav__nav-item.c360-nav__nav-item--l1.c360-nav__nav-item--l1-more .icon.more-arrow {
    display: block;
    float: right;
    content: url(https://a.sfdcstatic.com/shared/images/c360-nav/icons.svg#chevron-right-blue);
    transform: rotate(90deg) scale(0.8);
    margin-top: 8px;
    margin-left: 7px;
  }

  .utility-items {
    display: none;
  }

  .obscured-panel {
    visibility: hidden;
    display: block;
  }

  .c360-nav {
    --c360-nav-c-header-height: 96px;
  }

  .c360-nav__overlay {
    top: var(--nav-integration-c-header-height-desktop);
    height: calc(100vh - var(--nav-integration-c-header-height-desktop));
    height: calc((var(--vh, 1vh) * 100) - var(--nav-integration-c-header-height-desktop));
  }

  .c360-nav__header[data-has-context-nav='true'] .c360-nav__overlay {
    height: calc(100vh - var(--nav-integration-c-header-height-desktop) + (var(--nav-integration-c-header-height-desktop) * 0.25));
    height: calc((var(--vh, 1vh) * 100) - var(--nav-integration-c-header-height-desktop) + (var(--nav-integration-c-header-height-desktop) * 0.25));
  }

  .c360-nav__header[data-has-context-nav='false'] {
    height: var(--nav-integration-c-header-height-desktop);
  }

  .c360-nav__header[data-has-context-nav='true'] {
    height: calc(var(--nav-integration-c-header-height-desktop) * 0.75);
  }

  .c360-nav__header[data-has-context-nav='false'] .c360-nav__header-logo-img {
    width: var(--c360-nav-c-logo-width);
    height: var(--c360-nav-c-logo-height);
  }

  .c360-nav__header[data-has-context-nav='true'] .c360-nav__header-logo-img {
    width: calc(var(--c360-nav-c-logo-width) * 0.666666);
    height: calc(var(--c360-nav-c-logo-height) * 0.66666);
  }

  .c360-spacer[data-has-context-nav='true'] {
    padding-top: calc(var(--nav-integration-c-header-height-desktop) * 0.75);
  }

  .c360-nav__nav-panel.animation-panel-transition,
  .c360-nav__nav-panel .animation-panel-transition {
    transition: all 0.35s ease-in-out;
  }

  .l1-button {
    --sds-c-button-text-color-hover: #0176d3;
    width: 400px;
  }

  .l1-button::part(button) {
    font-family: var(--c360-g-font-family-sans);
    font-weight: bold;
  }

  .l2-button {
    --sds-c-button-text-color: #747474;
    --sds-c-button-spacing-block-start: 8px;
    --sds-c-button-spacing-block-end: 8px;
    --sds-c-button-color-background-hover: transparent;
  }

  .l2-hybrid-label {
    max-width: 78%;
  }

  .l2-hybrid-chevron {
    max-width: 34px;
  }

  .l2-button::part(button) {
    position: relative;
    z-index: 2;
    width: auto;
  }

  .l2-button::part(button):hover,
  .c360-nav__nav-items-list--l2 li .l2-button::part(button) {
    width: 100%;
  }

  .c360-nav__nav-items-list--l2 li .l2-hybrid-label::part(button) {
    width: auto;
  }

  .c360-nav__nav-items-list--l2 li .l2-hybrid-chevron::part(button) {
    max-width: 34px;
  }

  .c360-nav__nav-items-list--l2 li .l2-hybrid-container {
    display: inline-block;
    cursor: pointer;
    max-width: 367px;
  }

  .nav-item-label--l1 {
    max-width: 100%;
  }

  .nav-item-chevron--l2--container {
    max-width: 34px;
  }

  .nav-item-label--l2 {
    text-align: left;
    max-width: 78%;
    line-height: 28px;
  }

  .nav-item-label--l2-buttonlink {
    max-width: 100%;
  }

  .c360-spacer {
    padding-top: var(--nav-integration-c-header-height-desktop);
  }

  .c360-nav__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 40px;
  }

  .c360-nav__wrapper {
    width: 100%;
    display: flex;
  }

  .c360-nav__header--mobile {
    display: none;
  }

  .c360-nav__nav-items-list--l1 {
    flex-direction: row;
  }

  .c360-nav__nav-items.show {
    position: static;
    width: auto;
  }

  .c360-nav__nav-items-list--l1 li .l1-button span {
    font-size: 16px;
    line-height: 28px;
  }

  .c360-nav__nav-items-list--l2 li .l2-button span {
    float: left;
    font-size: 16px;
    margin-left: 12px;
    margin-right: 20px;
  }

  .c360-nav__nav-items-list--l2 li .l2-button span.arrow,
  .c360-nav__nav-items-list--l2 li .l2-button span.arrow-placeholder {
    position: relative;
    top: 3px;
    float: right;
    margin-right: 4px;
  }

  .c360-nav__nav-items-list--l2 li .l2-button span.arrow::before {
    content: url('https://a.sfdcstatic.com/shared/images/c360-nav/icons.svg#chevron-right-gray');
  }

  .c360-nav__nav-items-list--l2 li.active-l2 .l2-button span.arrow::before {
    content: url('https://a.sfdcstatic.com/shared/images/c360-nav/icons.svg#chevron-right-blue');
  }

  .c360-nav__nav-items-list--l2 li.active-l2 span.arrow {
    transition: opacity 0.35s ease-in-out 0.15s, transform 0.45s ease;
    opacity: 1;
    float: right;
    margin-right: 4px;
  }

  .c360-nav__nav-items-list--l2 li.active-l2 .l2-hybrid-label .nav-item-label--l2--container {
    color: var(--sds-c-button-text-color-hover);
  }

  .c360-nav__nav-items-list--l2 li.active-l2 .l2-hybrid-label .nav-item-label--l2--container:hover {
    color: var(--sds-g-color-palette-blue-50, '#0176d3');
  }

  .c360-nav__nav-items-list--l2 li.active-l2 .l2-hybrid-label a,
  .c360-nav__nav-items-list--l2 li.active-l2 .l2-hybrid-label .nav-item-label--l2 {
    color: inherit;
  }

  .c360-nav__nav-items-list--l2 li.active-l2 .l2-hybrid-label a:hover,
  .c360-nav__nav-items-list--l2 li.active-l2 .l2-hybrid-label .nav-item-label--l2:hover {
    color: inherit;
  }

  .c360-nav__l3-panel-inner-title {
    display: none;
  }

  .utility-nav-mobile {
    display: none;
  }

  .utility-icons {
    margin: 0;
    padding: 0 15px;
  }

  .c360-nav__wrapper,
  .utility-nav .utility-icons {
    height: 44px;
  }

  .c360-nav__header-logo,
  .utility-nav,
  .utility-nav .utility-icons,
  .utility-nav .utility-ctas {
    display: inline-flex;
    align-items: center;
  }

  .utility-nav .utility-icons .utility-icons-items {
    display: inline-block;
  }

  .utility-nav .utility-icons .utility-icons-items.login {
    min-width: 44px;
  }

  .utility-nav .utility-icons>img {
    margin: 0 4px;
    object-fit: contain;
    max-width: 18px;
    margin-bottom: -16px;
    background: transparent;
    border-radius: 100px;
    padding: 12px;
  }

  .utility-nav .utility-icons>img:active {
    background-color: #eaf5fe;
  }

  .utility-nav .utility-ctas hgf-cta {
    margin: 0 16px 0 0;
  }

  .mobile-logo {
    display: none;
  }

  .container-hamburger {
    display: none;
  }

  .mobile-search {
    display: none;
  }

  .c360-nav__nav-items {
    height: unset;
    display: block;
    flex-grow: 1;
    margin-left: var(--sds-g-spacing-5);
  }

  .c360-nav__nav-item {
    margin-top: 0px;
    margin-right: 0px;
  }

  .c360-nav__nav-item--l1 {
    float: left;
    margin: 0;
    padding: 0 var(--sds-g-spacing-4);
  }

  .c360-nav__nav-items-list--l1>li .l1-button span.arrow {
    display: none;
  }

  .c360-nav__nav-panel {
    visibility: hidden;
    display: flex;
    flex-direction: row;
    left: 0;
    top: var(--nav-integration-c-header-height-desktop);
    position: fixed;
    width: auto;
    transition: none;
  }

  .c360-nav__header[data-has-context-nav='true'] .c360-nav__nav-panel,
  .c360-nav__header[data-has-context-nav='true'] .c360-nav__overlay {
    top: calc(var(--nav-integration-c-header-height-desktop) * 0.75);
  }

  .c360-nav__l3-panel {
    display: none;
    box-sizing: border-box;
    padding: var(--c360-nav-c-padding-top) 40px 0px 40px;
    top: 0;
    width: 420px;
    background-color: #eaf5fe;
  }

  .c360-nav__nav-items-list--l2 li.active-l2 .c360-nav__l3-panel {
    display: block;
  }

  .c360-nav__l2-panel-message--mobile {
    display: none;
  }

  .c360-nav__l2-panel-message {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #eaf5fe;
    height: calc(100vh - var(--nav-integration-c-header-height-desktop));
    height: calc((var(--vh, 1vh) * 100) - var(--nav-integration-c-header-height-desktop));
    transition: none;
    width: 16px;
  }

  .c360-nav__header[data-has-context-nav='true'] .c360-nav__l2-panel-message {
    height: calc(100vh - var(--nav-integration-c-header-height-desktop) + (var(--nav-integration-c-header-height-desktop) * 0.25));
    height: calc((var(--vh, 1vh) * 100) - var(--nav-integration-c-header-height-desktop) + (var(--nav-integration-c-header-height-desktop) * 0.25));
  }

  .c360-nav__l2-panel-message-inner {
    opacity: 0;
    visibility: hidden;
  }

  .c360-nav__l2-panel-message-inner.promo-animation {
    transition: opacity 0.35s ease-in-out 0.35s;
  }

  li.active-l1 .c360-nav__l2-panel-message {
    width: 420px;
  }

  li.active-l1 .c360-nav__nav-panel .c360-nav__l2-panel-message.l2-panel-message-hide {
    display: none;
  }

  li.active-l1>div.c360-nav__nav-panel>div.c360-nav__l2-panel-message>div.c360-nav__l2-panel-message-inner {
    visibility: visible;
    opacity: 1;
  }

  .c360-nav__nav-items--l2 {
    box-sizing: border-box;
    margin-left: 0px;
    width: 568px;
    padding-left: 128px;
    display: table-cell;
    vertical-align: top;
    overflow-y: auto;
  }

  .c360-nav__nav-items--l2 h2 {
    margin: 56px auto 28px 20px;
    font-size: 40px;
    line-height: 40px;
  }

  .c360-nav__nav-items-list--l2 {
    margin-left: 9px;
    margin-right: 40px;
  }

  .c360-nav__nav-items-list--l2[data-l2-icons-variant='false'] {
    margin-left: 9px;
  }

  .c360-nav__nav-items-list--l2[data-l2-icons-variant='false'] .c360-nav__nav-item.c360-nav__nav-item--l2 a {
    margin-left: 12px;
  }

  .c360-nav__nav-item--l2 {
    display: block;
    position: relative;
    opacity: 0;
    line-height: 28px;
    min-height: 48px;
    max-width: 367px;
  }

  .c360-nav__nav-item--l2-background {
    cursor: pointer;
    position: absolute;
    top: 0px;
    z-index: 1;
    width: 70%;
    border-radius: var(--sds-c-button-radius-border);
    transition: width 0.25s ease-in-out, background-color 0.25s ease-in-out;
    height: 100%;
  }

  .c360-nav__nav-item--l2-background.hovered,
  .active-l2>.c360-nav__nav-item--l2-background {
    visibility: visible;
    background-color: #eaf5fe;
    width: 100%;
  }

  .c360-nav__nav-items--l2 .c360-nav__nav-items-list--l2>li.show {
    top: 0;
    opacity: 1;
  }

  .c360-nav__nav-items--l2 .c360-nav__nav-items-list--l2>li.waterfall-offset {
    top: -0.75em;
  }

  .c360-nav__nav-items--l2 .c360-nav__nav-items-list--l2>li.waterfall-show {
    transition: opacity 0.35s ease-in-out 0.15s, top 0.5s ease 0.15s;
    top: 0;
    opacity: 1;
  }

  .c360-nav__nav-items--l2 .c360-nav__nav-items-list--l2 li a {
    display: inline-block;
    font-weight: 700;
    color: #032d60;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 28px;
  }

  .c360-nav__nav-items--l2 .c360-nav__nav-items-list--l2 li span.nav-item-label--l2 a {
    margin: 0;
    color: var(--sds-c-button-text-color,
        var(--sds-s-button-text-color, inherit));
    display: flex;
    align-items: center;
  }

  .c360-nav__nav-items--l2 .c360-nav__nav-items-list--l2 li.active-l2 span.nav-item-label--l2 a {
    color: var(--sds-c-button-text-color-hover);
  }

  .c360-nav__l3-panel-inner {
    margin: 0px;
    padding: 0px;
  }

  .c360-nav__nav-item--mobile {
    display: none !important;
  }

  .l2-panel-back,
  .l3-panel-back {
    display: none;
  }

  .c360-nav__nav-items.show~.c360-nav__overlay {
    display: none;
  }
}

.utility-menu {
  display: none;
}

.utility-menu.show {
  display: block;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid rgba(24, 24, 24, 0.08);
  box-shadow: 0px 8px 10px 4px rgba(24, 24, 24, 0.08),
    0px 8px 12px -8px rgba(24, 24, 24, 0.16);
  border-radius: 16px;
  padding: 20px;
}

.utility-menu::after {
  position: absolute;
  content: '';
  border-width: 0 15px 20px 15px;
  border-color: transparent transparent #ffffff transparent;
  border-style: solid;
  filter: drop-shadow(0px -8px 4px rgba(24, 24, 24, 0.08));
}

.top.utility-menu::after {
  border-bottom-color: #ffffff;
  border-top: 0;
  top: -12px;
  left: 50%;
  margin-left: -12px;
}

.links p {
  font-size: 14px;
  line-height: 22px;
  color: #181818;
  font-weight: 500;
}

.separator {
  height: 1px;
  margin-left: var(--sds-g-spacing-5);
  margin-right: var(--sds-g-spacing-5);
  margin-top: var(--sds-g-spacing-5);
  margin-bottom: var(--sds-g-spacing-5);
  background-color: #e5e5e5;
}

.separator-vert {
  height: 15px;
  width: 1px;
  background-color: #e5e5e5;
  display: inline-block;
  margin-left: 9px;
  margin-right: 9px;
}

.force-inline {
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  display: flex;
}

.utility-nav-mobile {
  margin: 32px 24px 24px 24px;
}

.utility-nav-mobile .trial-container {
  --sds-c-button-width: 100%;
}

.phones-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.phone--single {
  width: 100%;
  text-align: center;
}

.phone--double:first-child {
  text-align: right;
  border-right: 1px solid #e5e5e5;
  padding: 0px 16px;
}

.phone--double:nth-child(2) {
  text-align: left;
  padding: 0px 16px;
}

@media (max-width: 345px) {
  .phone--double:first-child {
    padding: 0px 4px;
  }

  .phone--double:nth-child(2) {
    padding: 0px 4px;
  }
}

.phone--triple:first-child {
  width: 100%;
}

.phone--triple:nth-child(2) {
  text-align: right;
  border-right: 1px solid #e5e5e5;
  padding: 0px 16px;
}

.phone--triple:nth-child(3) {
  text-align: left;
  padding: 0px 16px;
}

@media (max-width: 345px) {
  .phone--triple:nth-child(2) {
    padding: 0px 4px;
  }

  .phone--triple:nth-child(3) {
    padding: 0px 4px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .c360-nav * {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
  }
}